import { AppProps } from 'next/app';

// eslint-disable-next-line react/display-name
export const withTheme = (Component: AppProps['Component']) => (props: AppProps) => {
  return (
    <div className="h-full">
      <Component {...props} />
    </div>
  );
};
